export const state = () => ({
  captchaToken: null,
  widgetId: null,
})
export const getters = {
  captchaToken(state) {
    return state.captchaToken
  },
  widgetId(state) {
    return state.widgetId
  }
}
export const mutations = {
  SET_CAPTCHA_TOKEN(state, data) {
    state.captchaToken = data
  },
  SET_CAPTCHA_WIDGET(state, data) {
    state.widgetId = data
  }
}
