
export default {
	data() {
		return {
			checked: false
		}
	},
	props: {
		label: String,
		link: String,
		error: {
			type: Boolean,
			default: false
		}
	}
}
