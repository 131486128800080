
import { mapActions, mapGetters } from "vuex"

export default {
  data() {
    return {
      modalComponentMemory: "",
      modalTitleMemory: "",
      modalSubTitleMemory: ""
    };
  },
  computed: {
    ...mapGetters({
      modalTitle: "modal/modal-main/modalTitle",
      modalSubTitle: "modal/modal-main/modalSubTitle",
      modalComponent: "modal/modal-main/modalComponent"
    })
  },
  methods: {
    ...mapActions({
      closeModal: "modal/modal-main/closeModal"
    }),
    closeHandler() {
      closeModal();
    }
  },
  mounted() {
    if (this.modalComponent) this.modalComponentMemory = this.modalComponent;
    if (this.modalTitle) this.modalTitleMemory = this.modalTitle;
    if (this.modalSubTitle) this.modalSubTitleMemory = this.modalSubTitle;
  }
};
