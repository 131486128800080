
import ButtonAutoteka from "@/components/button/button-autoteka"
import ButtonCompare from "@/components/button/button-compare"
import { mapActions, mapGetters, mapMutations } from "vuex"
// import cardClick from "~/mixins/cardClick";
import cardClick from "~/mixins/cardClick"
import filters from "~/mixins/filters"

export default {
  mixins: [filters, cardClick],
  components: { ButtonAutoteka, ButtonCompare },
  computed: {
    ...mapGetters({
      likesArray: "favorite/favorite/likesArray",
      currentCar: "modal/modal-choose/currentCar",
      settings: "settings/settings/settings"
    }),
    isEqual() {
      if (this.currentCar) {
        return this.currentCar.id === this.offer.id;
      } else {
        return false;
      }
    },
    creditText() {
      const formattedPrice = this.$options.filters.access_acredit(
        this.offer.price
      );
      return this.$store.state.domain === "https://leks-cars.ru"
        ? `В кредит от ${formattedPrice} / мес.`
        : "Получить предложение";
    }
  },
  props: {
    isForm: {
      type: Boolean,
      default: false
    },
    long: {
      type: Boolean,
      default: false
    },
    offer: {
      type: Object,
      default: () => {}
    },
    url: {
      type: String,
      default: ""
    },
    choose: {
      type: Boolean,
      default: false
    },
    isNewCar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions({
      liked: "favorite/favorite/liked",
      openModal: "modal/modal-main/openModal",
      closeModal: "modal/modal-main/closeModal"
    }),
    ...mapMutations({
      setCurrentCar: "modal/modal-choose/SET_CURRENT_CAR",
    }),
    async chooseClick(offer) {
      this.setCurrentCar(offer);
      await this.closeModal();
    },
    async like() {
      await this.liked(this.offer.external_id);
    },
    async call() {
      let payload = {
        modal_data: this.offer,
        modal_component: "modal-callback",
        modal_title: "Обратный звонок",
        modal_sub_title: this.offer.name
      };
      await this.openModal(payload);
    },
    callPhone() {
      var myLink = document.createElement("a");
      myLink.setAttribute("href", "tel:" + this.settings.phone);
      myLink.click();
    },
    async tradeIn(carInfo) {
      let payload = {
        modal_data: carInfo,
        modal_component: "modal-tradeIn",
        modal_title: "Заявка на Trade-In",
        modal_sub_title: carInfo.name
      };
      await this.openModal(payload);
    },
    async autoteka(carInfo) {
      let payload = {
        modal_data: carInfo,
        modal_component: "modal-autoteka",
        modal_title: "Отчет от " + carInfo.createdAt,
        modal_sub_title: carInfo.name
      };
      this.openModal(payload);
    },
    async credit() {
      let payload = {
        modal_data: this.offer,
        modal_component: "modal-credit",
        modal_title: "Заявка на автокредит",
        modal_sub_title: this.offer.name
      };
      await this.openModal(payload);
      await this.setCurrentCar(this.offer);

      // this.$router.push({
      // 	path: '/credit', query: {
      // 		mark: this.offer.mark.slug,
      // 		model: this.offer.folder.slug,
      // 		car: this.offer.external_id
      // 	}
      // })
    }
    // toCatalog() {
    // 	this.$router.push('/used')
    // }
  }
};
