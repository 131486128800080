
import filters from "@/mixins/filters"
import { mapGetters } from "vuex"

export default {
  mixins: [filters],
  computed: {
    ...mapGetters({
      settings: "settings/settings/settings",
      allMarks: "marks/marks/allMarks",
      t_bank: "banks/t_bank"
    })
  },
};
